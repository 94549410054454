/* eslint-disable react/no-danger */
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FaFacebookMessenger } from 'react-icons/fa'

import SEO from '../components/seo'
import Layout from '../components/Layout'
import MarkdownContent from '../components/MarkdownContent'

import '../components/contact.css'

export default ({ data, location }) => {
  const { frontmatter } = data.ContactPage
  const { googleMaps } = data.siteData.siteMetadata
  return (
    <div>
      <SEO title="Kontakt" pathname={location.pathname} keywords="kontakt, Bielsko-Biała, szkoła językowa" />
      <Layout>
        <div className="contact">
          {googleMaps !== ''
            ? (
              <div className="contact__media--map">
                <iframe className="contact__media--element" title="googleMap" src={googleMaps} frameBorder="0" allowFullScreen />
              </div>
            )
            : <Img className="contact__media--element" fluid={frontmatter.image.childImageSharp.fluid} alt="Contact" />
          }
          <div className="contact__info">
            <h1>{frontmatter.title}</h1>
            <p className="contact__info--item">{frontmatter.name}</p>
            {(frontmatter.address_line_1 !== undefined
              && frontmatter.address_line_2 !== undefined)
              && (
              <div>
                <p className="contact__info--item">{frontmatter.address_line_1}</p>
                <p className="contact__info--item">{frontmatter.address_line_2}</p>
              </div>
              )
            }
            <p className="contact__info--item">tel. <a href={`tel:${frontmatter.phone_number}`}>{frontmatter.phone_number}</a></p>
            <p>e-mail: <a className="contact__info--item" key="mailLink" href={`mailto:${frontmatter.e_mail}`}>{frontmatter.e_mail}</a></p>
            {frontmatter.messenger &&
              <div className="contact__info--item"><FaFacebookMessenger className="footer__column-links--social" /> <a href={frontmatter.messenger} target='blank'> <span>{frontmatter.messenger}</span></a></div>
            }
            <MarkdownContent content={frontmatter.description} />
          </div>
        </div>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query {
    ContactPage: markdownRemark( frontmatter: { templateKey: { eq: "contact-page" }}) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 800, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        name
        phone_number
        e_mail
        description
        messenger
      }
    }
    siteData: site {
      siteMetadata {
        googleMaps
      }
    }
  }
`
